.box__terms {
    .box {
        &__header {
            padding: 12px 0;
            border-bottom: 1px solid $color-gray-100;
            background-color: $color-white;
            
            .title {
                margin-bottom: 0;
                color: $color-gray-900;
                font-family: $gmarketSans;
                font-weight: 400;
                font-size: 18px;
                line-height: 24px;
                text-align: center;
            }
        }

        &__terms-content {
            position: relative;
            min-width: 0;
            padding: 32px 16px 40px;
        }

        &__legal {
            font-family: "Noto Sans KR",sans-serif;

            &-notice {
                margin-bottom: 16px;
        
                &:last-child {
                    margin-bottom: 0;
                }
    
                &-desc {
                    padding: 16px;
                    border-radius: 4px;
                    background-color: $color-white;
        
                    > .list__term {
                        margin-top: 0;
                    }
                }
            }
        }
        
        &__table {
            .table {
                overflow: hidden;
                width: 100%;
                border: 1px solid $color-gray-200;
                border-collapse: separate;
                border-radius: 4px;
                text-align: center;
            }

            thead {
                th {
                    background-color: $color-gray-100;

                    .text {
                        color: $color-gray-700;
                    }
                }
            }

            tbody {
                tr:last-child {
                    td {
                        border-bottom: 0;
                    }
                }
            }

            th,
            td {
                position: relative;
                height: auto;
                padding: 8px;
                border-bottom: 1px solid $color-gray-200;

                .text {
                    display: block;
                }
        
                &:first-of-type {
                    .text {
                        color: $color-gray-700;
                    }
                }

                &:not(:last-of-type) {
                    &::after {
                        content: "";
                        position: absolute;
                        top: 0;
                        right: 0;
                        width: 1px;
                        height: 100%;
                        background: $color-gray-200;
                    }
                }
            }

            + .text {
                margin-top: 8px;
            }
        }
    }

    .title {
        margin-bottom: 16px;
        font-family: $gmarketSans;
        font-size: 18px;
        line-height: 24px;

        &__term {
            margin-bottom: 8px;
            color: $color-gray-900;
            font-size: 12px;
            line-height: 16px;
        }

        &__notice {
            margin-bottom: 8px;
            color: $color-gray-600;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
        }

        &__desc {
            color: $color-gray-500;
            font-size: 12px;
            line-height: 16px;
        }
    }

    .text {
        color: $color-gray-500;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        vertical-align: middle;

        + .text {
            margin-top: 8px;
        }

        &__num {
            margin-right: 2px;

            + .text {
                margin-top: 0;
            }
        }

        &__emphasis {
            font-weight: 700;
        }

        + .title__desc {
            margin-top: 8px;
        }
        
        + .list__term {
            margin-top: 8px;
        }

        + .box__table {
            margin-top: 4px;
        }
    }
    
    .list {
        &__term {
            .list__term {
                width: 100%;
                margin-top: 4px;
                padding-left: 13px;
            }
        }
        
        &-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 8px;
            
            .list-item {
                margin-bottom: 4px;
            }
            
            &:last-child {
                margin-bottom: 0;
            }

            .text:not(.text__num) {
                flex: 1;
            }
        }
    }

    .link {
        &__text {
            color: #0028ac;
        }
    }
}
